<template>
  <div id="NewsDetail" class="section">
    <div class="columns is-multiline is-vcentered">
      <!-- Back Button -->
      <div class="column is-1">
        <div @click.prevent="navigateToNewsManage">
          <b-icon class="clickable-icon" icon="chevron-left" size="is-medium" />
        </div>
      </div>
      <!-- Title -->
      <div class="column is-7-desktop is-7-tablet is-12-mobile">
        <h1 class="title">NEWS DETAILS</h1>
      </div>
      <!-- Create Button -->
      <div
        class="column is-2 is-hidden-mobile"
        :class="newsObject ? '' : 'is-offset-2'"
      >
        <b-button
          expanded
          type="is-primary"
          icon-left="upload"
          :disabled="disableCreate"
          @click.prevent="uploadNewsButtonPressed"
        >
          {{ newsObject ? "Update" : "Create" }}
        </b-button>
      </div>
      <!-- Remove Button -->
      <div v-if="newsObject" class="column is-2 is-hidden-mobile">
        <b-button
          expanded
          outlined
          type="is-danger"
          icon-left="close"
          @click.prevent="presentConfirmRemoveNews"
        >
          Remove
        </b-button>
      </div>
    </div>

    <div class="container">
      <div style="margin-top: 1.5rem">
        <div class="columns">
          <div class="column has-text-centered">
            <!-- Image Upload -->
            <b-field label="Cover Image" />
            <image-file-upload
              :mainImageUrl="mainImage"
              @uploaded_file="val => (mainImage = val)"
            />
            <p style="padding-top: 1rem; color: #676767">
              Aspect Ratio 3:2 ( recommended 1800x1200px ) max size: 2048px
            </p>
          </div>
          <div class="column">
            <!-- Title + Subtitle + Type -->
            <b-tabs type="is-boxed">
              <!-- ENGLISH -->
              <b-tab-item label="English">
                <b-field label="Title">
                  <b-input v-model="en_title" />
                </b-field>
                <b-field label="Short Description">
                  <b-input
                    v-model="en_shortDesc"
                    type="textarea"
                    maxlength="80"
                  />
                </b-field>
                <b-field label="Full Description">
                  <b-input v-model="en_fullDesc" type="textarea"></b-input>
                </b-field>
              </b-tab-item>
              <!-- CHINESE -->
              <b-tab-item label="Chinese">
                <b-field label="Title">
                  <b-input v-model="zh_title" />
                </b-field>
                <b-field label="Short Description">
                  <b-input
                    v-model="zh_shortDesc"
                    type="textarea"
                    maxlength="80"
                  />
                </b-field>
                <b-field label="Full Description">
                  <b-input v-model="zh_fullDesc" type="textarea"></b-input>
                </b-field>
              </b-tab-item>
              <!-- THAI -->
              <b-tab-item label="Thai">
                <b-field label="Title">
                  <b-input v-model="th_title" />
                </b-field>
                <b-field label="Short Description">
                  <b-input
                    v-model="th_shortDesc"
                    type="textarea"
                    maxlength="80"
                  />
                </b-field>
                <b-field label="Full Description">
                  <b-input v-model="th_fullDesc" type="textarea"></b-input>
                </b-field>
              </b-tab-item>
            </b-tabs>

            <b-field label="Video URL">
              <b-input v-model="video_url"></b-input>
            </b-field>
            <multi-image-file-upload
              :type="'news'"
              :existingContentUrls="photos"
              @uploaded_files="setUploadedContentImages"
            />
          </div>
        </div>
      </div>
      <!-- Mobile : Create Button -->
      <div class="is-hidden-tablet" style="margin-top: 1.5rem">
        <b-button
          expanded
          type="is-primary"
          @click.prevent="uploadNewsButtonPressed"
        >
          {{ newsObject ? "Update" : "Create" }}
        </b-button>
      </div>
      <!-- Mobile: Remove Button -->
      <div v-if="newsObject" class="is-hidden-tablet" style="margin-top: 1rem">
        <b-button
          expanded
          outlined
          type="is-danger"
          icon-left="close"
          @click.prevent="presentConfirmRemoveNews"
        >
          Remove
        </b-button>
      </div>
    </div>
  </div>
</template>

<script>
import NewsApi from "@/apis/NewsApi";
import AlertManager from "@/utils/AlertManager";
import ImageFileUpload from "@/components/forms/ImageFileUpload";
import MultiImageFileUpload from "@/components/forms/MultiImageFileUpload";

export default {
  name: "NewsDetail",
  components: {
    ImageFileUpload,
    MultiImageFileUpload
  },
  props: ["news"],
  data() {
    return {
      mainImage: null,
      en_title: null,
      en_shortDesc: null,
      en_fullDesc: null,
      zh_title: null,
      zh_shortDesc: null,
      zh_fullDesc: null,
      th_title: null,
      th_shortDesc: null,
      th_fullDesc: null,
      video_url: null,
      photos: [],
      uploadPhotos: [],
      isEditExisting: false,
      newsObject: this.news
    };
  },
  computed: {
    disableCreate: function() {
      if (
        (this.mainImage &&
          this.en_title &&
          this.en_shortDesc &&
          this.en_fullDesc &&
          this.zh_title &&
          this.zh_shortDesc &&
          this.zh_fullDesc &&
          this.th_title &&
          this.th_shortDesc &&
          this.th_fullDesc) ||
        this.newsObject
      ) {
        return false;
      } else {
        return true;
      }
    }
  },
  methods: {
    getNewsDetail: async function(news_id) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const response = await NewsApi.requestNewsInfoById(news_id);
      loadingComponent.close();

      this.newsObject = response;
      this.mainImage = response.cover_image;

      this.en_title = response.header.en;
      this.en_shortDesc = response.short_description.en;
      this.en_fullDesc = response.description.en;

      this.zh_title = response.header.zh;
      this.zh_shortDesc = response.short_description.zh;
      this.zh_fullDesc = response.description.zh;

      this.th_title = response.header.th;
      this.th_shortDesc = response.short_description.th;
      this.th_fullDesc = response.description.th;

      this.photos = response.photos;
      this.video_url = response.video;
    },
    setUploadedContentImages: function(urls) {
      this.uploadPhotos = urls;
    },
    uploadNewsButtonPressed: async function() {
      let params = {
        cover_image: this.mainImage,
        header: {
          en: this.en_title,
          th: this.th_title,
          zh: this.zh_title
        },
        short_description: {
          en: this.en_shortDesc,
          th: this.th_shortDesc,
          zh: this.zh_shortDesc
        },
        description: {
          en: this.en_fullDesc,
          th: this.th_fullDesc,
          zh: this.zh_fullDesc
        }
      };

      // Append existing photos
      params.photos = [];
      for (let index = 0; index < this.photos.length; index++) {
        const existing_photo = this.photos[index];
        params.photos.push(existing_photo);
      }
      // Upload News' content images
      if (this.uploadPhotos.length > 0) {
        // Upload new images the append return url to params
        for (let index = 0; index < this.uploadPhotos.length; index++) {
          const new_photo = this.uploadPhotos[index];
          params.photos.push(new_photo);
        }
      }

      if (this.video_url && this.video_url != "") {
        params.video = this.video_url;
      }
      // Conditional update if _id is exist, then will create instead
      if (this.newsObject) {
        this.updateExistingNews(this.newsObject._id, params);
      } else {
        this.createNews(params);
      }
    },
    createNews: async function(newsInfo) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const response = await NewsApi.requestCreateNews(newsInfo);
      loadingComponent.close();
      AlertManager.showSuccessSnackbar(
        "Banner " + response.header.en + " was scucessfully created."
      );
      this.newsObject = response;
    },
    updateExistingNews: async function(news_id, newsInfo) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      await NewsApi.requestUpdateNews(news_id, newsInfo);
      loadingComponent.close();
      AlertManager.showSuccessSnackbar("News was scucessfully updated.");
    },
    presentConfirmRemoveNews: function() {
      if (this.newsObject._id) {
        this.$buefy.dialog.confirm({
          title: "Confirm Remove Banner",
          message:
            "Are you sure you want to permanently remove <b>" +
            this.newsObject.header.en +
            "</b>?",
          confirmText: "Confirm",
          type: "is-danger",
          hasIcon: true,
          onConfirm: () => this.removeNews(this.newsObject._id)
        });
      }
    },
    removeNews: async function(news_id) {
      const loadingComponent = this.$buefy.loading.open({
        container: null
      });
      const response = await NewsApi.removeNewsById(news_id);
      loadingComponent.close();
      if (response) {
        this.navigateToNewsManage();
      }
    },
    navigateToNewsManage: function() {
      this.$router.push({ name: "news" });
    }
  },
  mounted() {
    if (this.newsObject) {
      this.getNewsDetail(this.newsObject._id);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#NewsDetail {
  text-align: left;
}
.clickable-icon {
  cursor: pointer;
}
</style>
